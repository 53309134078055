/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-20",
    versionChannel: "nightly",
    buildDate: "2024-04-20T00:05:25.553Z",
    commitHash: "955d03885eccc1c29514dd1cb3e03ac504f03c49",
};
